/**
 * ----------------------------------------
 * WAYPOINTS
 * ----------------------------------------
 */

 .fiftyfifty {
    display: flex;
    justify-content: space-between;
  }
  
  .fiftyfifty .basic {
    flex-basis: 50%;
  }
  .fiftyfifty .basic:last-of-type {
    text-align: right;
  }
  
  .js .to_fade_up,  .js .to_fade_up0, .js .to_fade_up2, .js .to_fade_up3,
  .js .to_fade_from_left, 
  .js .to_fade_from_right,.js .to_fade_from_right1 {
    visibility: hidden;
  }
  .js .fade_up {
    visibility: visible;
    -ms-animation: fadeUp 1s; /* Internet Explorer*/
    animation: fadeUp 1s; 
  }

  .js .fade_from_left {
    visibility: visible;
    -ms-animation: fadeFromLeft 1s; /* Internet Explorer*/
    animation: fadeFromLeft 1s; 
  }
  .js .fade_from_right {
    visibility: visible;
    -ms-animation: fadeFromRight 1s; /* Internet Explorer*/
    animation: fadeFromRight 1s; 
  }
  
  
  @keyframes fadeUp {
    0% {
      filter: alpha(opacity=0);
      opacity: .1;
      transform: translateY(100px);
    }
    100% {
      filter: alpha(opacity=100);
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeFromLeft {
    0% {
      filter: alpha(opacity=0);
      opacity: .1;
      transform: translateX(-100%);
    }
    100% {
      filter: alpha(opacity=100);
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fadeFromRight {
    0% {
      filter: alpha(opacity=0);
      opacity: .1;
      transform: translateX(100%);
    }
    100% {
      filter: alpha(opacity=100);
      opacity: 1;
      transform: translateX(0);
    }
  }