/* ----------------------------------------------
 * Generated by Animista on 2021-11-30 11:58:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */

 body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    letter-spacing: 0px;
 }

::-moz-selection
 {
    background: #000;
    color: $clr-background;
 }
 
::selection {
    background: #000;
    color: $clr-background;
 }

 a{
    cursor: pointer !important;
    display: block;
 }


 header{
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 99999;

    h4{
        position: absolute;
        font-size: 1.6rem;
        left: 10%;
        bottom: 1rem;
    }
 }


 .intro{
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #FFF;
    transform: .5s;
    transition: all .5s;

    &.top{
        top: -100vh;
        transition: all .5s;
    }
 }

 .logo-header{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #000;
    flex-direction: column;

    .wrapper{
      display: flex;
      flex-direction: column;
    }

    @include bp-576{
      margin-left: 20px;
      align-items: flex-start;
    }
 }

 .logo{
    position: relative;
    display: inline-block;
    bottom: -20px;
    opacity: 0;
    left: 0;
    font-family: $font-serif;
 }

 .logo.active{
    bottom: 0;
    opacity: 1;
    transition: ease-in-out .5s;
 }

 .logo1{
    transition: all .5s;
    left: 0px;
 }

 .logo1.activo{
    opacity: 1;
    left: 50px;
    transition: all .5s;
 }

 .logo.fade{
    bottom: 150px;
    opacity: 0;
    left: 0;
    transition: ease-in-out .5s;
 }

 .m31e svg{
    width: auto;
    height: 50px;
    display: inline;
    margin-bottom: 12px;

    @include bp-576{
      height: 25px;
    }
 }

.arqu {   
   &.activo{
      svg{
         right: -130px;
         transition: ease-in-out .5s;

         @include bp-576{
            right: -80px;
          }
      } 
   }

   svg{
      width: auto;
      height: 60px;
      display: inline;
      margin: -5px auto 3px auto; 
      right: 0px;

      @include bp-576{
         height: 31px;
       }
   }
}

 .interior svg{
    width: auto;
    height: 47px;
    display: inline;

    @include bp-576{
      height: 24.5px;
    }
 }

///////////////////////////////////////////////////////
 header{
   margin-top: 1rem;
   position: fixed;

   .menu{
      text-align: right;

      a{
         margin-bottom: 5px;

         &:hover{
            text-decoration: underline;
         }
      }
   }
 }
 

 .portfolio-home{
//   margin-top: 10vh;
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   grid-gap: 4% 10%;

//   top: 200vh;
//   transition: ease-in-out .5s;
  padding: 0 4%;
  margin-top: -3%;
  display: flex;
  flex-wrap: wrap;
  gap: 8%;
  align-items: center;


  .container{
      // display: flex;
      // align-items: center;
      width: 46%;
      margin-top: 12%;

      &:first-child, &:nth-child(2){
         margin-top: 6%;
      }

      @include bp-576{
         width: 100%;
         margin-top: 25%;
      }

      &.p-left{
         justify-content: flex-start;
         display: flex;

         @include bp-576{
            justify-content: center;
         }
      }

      &.p-right{
         justify-content: flex-end;
         display: flex;

         @include bp-576{
            justify-content: center;
         }
      }

      &.p-centered{
         justify-content: center;
         display: flex;
      }
  }

  .content {
      // position: relative;
      width: auto;
      overflow: hidden;
      // align-items: center;
      // display: flex;

      @include bp-576{
         margin: 0 auto;
      }

      img{
         display: block;
      }

      &.full{
         width: 100%;
      }

      &.mediano{
         width: 65%;

         @include bp-576{
            width: 53%;
         }
      }

      &.petit{
         width: 50%;
      }
   }
 
   .content .content-overlay {
      background: $clr-black;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
      z-index: 9;

      &.smoke{
         background: $clr-smoke;
      }

      &.stone{
         background: $clr-stone;
      }
   }
 
 .content:hover .content-overlay{
   opacity: 1;
 }
 
 .content-image{
   width: 100%;
 }
 
 .content-details {
   position: absolute;
   width: 100%;
   z-index: 99;
   // top: 50%;
   // left: 50%;
   opacity: 0;
   // -webkit-transform: translate(-50%, -50%);
   // -moz-transform: translate(-50%, -50%);
   // transform: translate(-50%, -50%);
   -webkit-transition: all 0.4s ease-in-out 0s;
   -moz-transition: all 0.4s ease-in-out 0s;
   transition: all 0.4s ease-in-out 0s;


   .text {
      color: #CECECE;
      font-size: 20px;
      text-align: left;
      justify-content: space-between;
      width: 100%;
      padding: 2%;

      h2, h3{
         font-size: size(18);
         margin: 0;
         line-height: size(23);
      }

      h3{
         font-family: $font-serif;
         font-weight: $font-light;
      }

      &.stone{
         color: #000;
      }
   }
 }
 
 .content:hover .content-details{
   top: 0;
   opacity: 1;
 }
 
//  .fadeIn-bottom{
//    top: 80%;
//  }
   // .text {
   //    color: white;
   //    font-size: 20px;
   //    text-align: left;
   //    justify-content: space-between;
   //    width: 100%;
   //    padding: 2%;

   //    h2, h3{
   //       font-size: size(18);
   //       margin: 0;
   //       line-height: size(23);
   //    }

   //    h3{
   //       font-family: $font-serif;
   //       font-weight: $font-light;
   //    }
   // }

   .flecha{
      position: fixed;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
   }

} 

footer{
   background: #FFF;
   padding: 25px 0;
   margin-top: 100px;
   // position: absolute;
   right: 0;
   bottom: -15px;
   left: 0;
   

   @include bp-576{
      padding: 25px 0;
   }

   .-flex{
      align-items: flex-end;
      @include bp-576{
         display: block;
      }
   }

   img{
      @include bp-576{
         margin-bottom: 60px;
      }
   }

   .links{
      text-align: right;
      width: 400px;

      @include bp-576{
         text-align: left;
         width: 100%;
      }

      a{
         &:hover{
            text-decoration: underline;
         }
      }
   }
}

.projectes{
   margin-top: 17vh;
   margin-bottom: 44vh;
   ul{
      li{
         margin: 15px 0;

         span.hiddentxt a{
            font-size: size(32);
            font-weight: 600;

            @include bp-576{
               font-size: size(22);
            }
         }

         a.numero{
            font-family: $font-serif;
            font-size: size(20);
            font-weight: 100;
            top: 0;
            margin-left: 5px;

            @include bp-576{
               font-size: size(16);
            }
         }
      }
   }

   .hiddenimg {
      display: none;

      img{
         width: 65%;
         max-height: 100vh;

         @include bp-576{
            width: 100%;
         }
      }
    }
    
    .hiddentxt {
      font-weight: bold;
      z-index:99;
    }
    
    .hiddentxt a {
      text-decoration: none;
      z-index: 99;
    }

    .hiddentxt:hover ~ .hiddenimg {
      display: block;
      position: fixed;
      z-index: 2;
      left: 50%;
      transform: translate(0, -50%);
      top: 50%;
    }
}

.filtres{
   font-size: size(20);
   position: fixed;
   top: 0;
   right: 0;
   z-index: 999;
   padding: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-end;
   height: 100vh;
   width: 50%;

   a{
      // font-weight: 400;
   }

   .-flex{
      flex-direction: column;
   }

   @include bp-576{
      position: relative;
      transform: translate(0, 0);
      left: 0;
      margin-top: 20px;
   }

   span{
      font-family: $font-serif;
      margin-right: 5px;
      font-weight: 200;
   }

   div{
      margin: 5px 9px 5px 0;

      @include bp-576{
         margin-right: 18px;
      }
   }
}

.info{
   background: $clr-stone;
   width: 100%;

   @include bp-576{
      padding-top: 20vh;
   }

   h2{
      font-family: $font-serif;
      font-weight: 100;
      font-size: size(20);
      line-height: size(24);
      margin: 0;
   }

   a, h3, h4, p, li{
      font-size: size(18);
      margin: 0;
      line-height: size(24);
   }

   h2{
      margin-bottom: 5px;
   }

   h4{
      color: #676166;
   }

   ul{
      margin-top: 25px;
      font-weight: 600;
   }
   

   .infoplus{
      height: 100vh;
      align-items: flex-end;
      justify-content: flex-end;
      bottom: 20px;

      @include bp-576{
         display: block;
         height: auto;
      }

      div{
         width: 100%;

         @include bp-576{
            width: 100%;
         }
      }

      .-flex{
         justify-content: flex-end;
         align-items: flex-end;
         width: 50%;

         @include bp-576{
            display: block;

            a{
            margin-top: 20px;
            }
         }
      }
   }

   .contacte{
      div{
         margin-bottom: 30px;

         &:last-child{
            margin: 60px 0 0 0;

            @include bp-1440{
               margin: 30px 0 0 0;
            }
         }
      }
   }
}

.projecte-detall{

   @include bp-576{
      display: block;
      margin-top: 0;
   }

   .descripcio{
      width: 25%;
      margin-top: 40vh;

      @include bp-576{
         width: 91%;
         position: absolute;
         top: 0;
      }

      h1{
         font-family: $font-serif;
         font-size: size(20);
         line-height: size(22);
         margin:0 0 4px 0;
         font-weight: 100;
      }

      p{
         font-size: size(18);
         line-height: size(22);
         width: 93%;
      }

      div{
         position: fixed;
         width: 25%;

         @include bp-576{
            width: 100%;
            position: relative;
         }
      }
   }

   .fotos{
       width: 80%;
       margin: 0 auto;

       @include bp-576{
         width: 100%;
         // margin-top: 100vh;
         // background: #FFF;
      }

      .slideNumber{
         position: fixed;
         bottom: 20px;
         text-align: center;
         z-index: 99999999;
         justify-content: center;
         font-size: 1.125rem;
         line-height: 1.375rem;
         font-weight: 600;
         width: 75.5%;

         @include bp-576{
            width: 90%;
         }
      }

       div{
         // margin: 0 auto;

         // @include bp-576{
         //    margin-bottom: 100px;
         // }
       }

       img{
         width: 100%;
         object-fit: cover;
       }

       .section{
         &.solo{
            display: none;
            background: transparent !important;
   
            @include bp-576{
               display: block;
            }
          }

         @include bp-576{
            background: #FFF;
         }
       }

       

       .l-horizontal{
         width: 100%;
         max-height: 100vh;
         display: flex;
         justify-content: center;
         align-items: center;

         img{
            width: 90%;
            margin: 0 auto;
            max-height: 100vh;
         }

         @include bp-576{
            width: 100%;
            height: auto;
         }
       }

       .s-horizontal{
         width: 100%;
         height: 100vh;
         display: flex;
         align-items: center;
         justify-content: center;
         @include bp-576{
            height: auto;
         } 
         img{
            width: 60%;
            margin: 0 auto;

            @include bp-576{
               width: 70%;
            }
         }

         
       }

       .m-horizontal{
         width: 100%;
         height: 100vh;
         display: flex;
         justify-content: center;
         align-items: center;
         @include bp-576{
            height: auto;
         } 

         img{
            width: 75%;
            margin: 0 auto;
            max-height: 100vh;

            @include bp-576{
               width: 80%;
            }
         }
       }

       .l-vertical{
         width: 100%;
         height: 100vh;
         display: flex;
         justify-content: center;
         align-items: center;
         @include bp-576{
            height: auto;
            width: 100%;
         } 

         img{
            width: 60%;
            height: 100vh;

            @include bp-576{
               width: 100%;
               height: auto;
            }
         }
       }

       .m-vertical{
         width: 100%;
         height: 100vh;
         display: flex;
         justify-content: center;
         align-items: center;

         @include bp-576{
            height: auto;
         } 

         img{
            width: 41%;
            height: 88vh;

            @include bp-576{
               width: 100%;
               height: auto;
            }            
         }
       }

       .s-vertical{
         width: 100%;
         height: 100vh;
         display: flex;
         justify-content: center;
         align-items: center;

         @include bp-576{
            height: auto;
         } 

         img{
            width: 35%;
         }
       }

       .hortizontal-2{
         width: 100%;
         height: 100vh;
         display: flex;
         justify-content: space-around;
         align-items: center;

         @include bp-576{
            height: auto;
         } 

         img{
            width: 40%;
            height: auto;


            @include bp-576{
               width: 48%;
            }
         }
       }

       .vertical-2{
         width: 100%;
         height: 100vh;
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 5%;

         @include bp-576{
            gap:2%;
            height: auto;
         }

         img{
            width: 30%;
            height: auto;

            @include bp-576{
               width: 47%;
            }
         }
       }

       .vertical-dcha{
         justify-content: right;
         display: flex;
         height: 100vh;
         
         @include bp-576{
            height: auto;
         }
         img{
            width: 50%;

            @include bp-576{
               width: 46%;

               @include bp-576{
                  width: 70%;
               }
            }
         }
       }

       .vertical-2-ml{
         justify-content: space-between;
         align-items: center;
         display: flex;
         @include bp-576{
            height: auto;
         }
         
         img:first-child{
            width: 30%;
            height: 100%;
            margin: 0 auto;

            @include bp-576{
               width: 35%;
            }
         }

         img:nth-child(2){
            width: 50%;
            height: 100vh;

            @include bp-576{
               height: auto;
            }
         }
       }

       .vertical-2-lm{
         justify-content: space-between;
         align-items: center;
         display: flex;
         @include bp-576{
            height: auto;
         }

         img:first-child{
            width: 50%;
            height: 100vh;

            @include bp-576{
               height: auto;
            }
         }

         img:nth-child(2){
            width: 30%;
            height: 100%;
            margin: 0 auto;

            @include bp-576{
               width: 35%;
               height: auto;
            }
         }
       }

       .vertical-2-vh{
         justify-content: space-between;
         align-items: center;
         display: flex;
         
         @include bp-576{
            height: auto;
         }

         img:first-child{
            width: 40%;
            height: 100%;
            margin: 0 auto;

            @include bp-576{
               width: 45%;
            }
         }

         img:nth-child(2){
            width: 50%;
            height: 100vh;

            @include bp-576{
               height: auto;
            }
         }
       }

       .vertical-2-hv{
         justify-content: space-between;
         align-items: center;
         display: flex;
         @include bp-576{
            height: auto;
         }

         img:first-child{
            width: 50%;
            height: 100vh;
         }

         img:nth-child(2){
            width: 40%;
            height: 100%;
            margin: 0 auto;

            @include bp-576{
               width: 45%;
            }
         }
       }
       



   }
}

.fp-warning, .fp-watermark a{
   display: none !important;
}


.error{
   height: 100vh;
   justify-content: center;
   display: flex;
   align-items: center;
   flex-direction: column;
}






