// Font weights
$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

/*
    OPTION A: Google fonts
    OPTION B: Custom fonts
*/

/*
    OPTION A: Google fonts
*/

// Maven Pro: 
// @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600&display=swap');
/*
    OPTION B: Custom fonts
*/

@font-face {
    font-family: 'ABCD';
    src: url('../fonts/ABCDiatype-Regular.ttf') format('truetype');
    src: url("../fonts/ABCDiatype-Regular.otf") format("opentype");
    src: url('../fonts/ABCDiatype-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCDiatype-Regular.eot'),
        url('../fonts/ABCDiatype-Regular.woff') format('woff'),
        url('../fonts/ABCDiatype-Regular.woff2') format('woff2'),
        url('../fonts/ABCDiatype-Regular.svg') format('svg');
    font-weight: 100;
    font-style: normal;
}


@font-face {
    font-family: 'Reckless';
    src: url('../fonts/Reckless.ttf') format('truetype');
    src: url('../fonts/Reckless.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Reckless.eot'),
        url('../fonts/Reckless.woff2') format('woff2'),
        url('../fonts/Reckless.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



$font-primary: 'ABCD', sans-serif; 
$font-serif:  'Reckless', serif;


