// Default colors
$clr-white: rgb(255, 255, 255);
$clr-gray-light: rgb(244, 244, 244);
$clr-gray-medium: #8B8B8B;
$clr-gray-dark: #464749;
$clr-txt: #24272a;
$clr-error: rgb(215, 40, 40);
$clr-gray: #D4D5D6;
$clr-background: #ECEDE8;
$clr-black: #030303;
$clr-stone: #E3DDD8;
$clr-smoke: #665E5D;



// App colors
$clr-primary: #24272a;
$clr-secondary: rgb(92, 121, 139);

// Background colors
.-bg--white { 
    @include bg-color($clr-white, 1);
    @include color($clr-txt, 1); 
}
.-bg--black { 
    @include bg-color($clr-black, 1);
    @include color($clr-white, 1);  
}
.-bg--gray-light { 
    @include bg-color($clr-gray-light, 1);
    @include color($clr-txt, 1); 
}
.-bg--gray-medium { 
    @include bg-color($clr-gray-medium, 1);
    @include color($clr-txt, 1); 
}
.-bg--gray-dark { 
    @include bg-color($clr-gray-dark, 1);
    @include color($clr-white, 1); 
}
.-bg--primary {
    @include bg-color($clr-primary, 1);
    @include color($clr-white, 1);
} 
.-bg--secondary {
    @include bg-color($clr-secondary, 1);
    @include color($clr-white, 1);
} 
