// Positioning
@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;
}
@mixin absolute($args: '') {
    @include position(absolute, $args);
}

@mixin fixed($args: '') {
    @include position(fixed, $args);
}

@mixin relative($args: '') {
    @include position(relative, $args);
}
// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity = $opacity-ie); //IE8
}

// Gradients
@mixin gradient($direction, $list) { 
    background: -webkit-linear-gradient($direction, $list);
    background: -moz-linear-gradient($direction, $list);
    background: -o-linear-gradient($direction, $list);
    background: linear-gradient($direction, $list);  
}

// Background cover
@mixin bgcover(){
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
// Color
@mixin bg-color($color, $opacity) {
    background-color: rgba($color, $opacity);
}
@mixin color($color, $opacity) {
    color: rgba($color, $opacity);
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: $size;
  line-height: calculateRem($size);
}

@mixin lazy {
    opacity: 0;
    transition: opacity 0.7s ease-in;  
}

@mixin centrarvert{
    display: flex;   
    align-items: center;}

@mixin h2pos{
    @include font-size(50);
    @include font-line-height(55);
    font-weight: $font-semibold;
    text-transform: uppercase;
    @include bp-1280{
        @include font-size(40);
        @include font-line-height(45);
    }
}



// Convert to rem
// The default font size for html is 16px
$base-size: 16;
//Function to convert px value into rem
//You can replace rem with any other value you want (rem is most preferred value for web)
@function size($target, $context: $base-size) {
    @return ($target / $context) * 1rem;
}





//CUSTOM MIXINS


@mixin linea-amarilla {
    ul{
        li{
            a{
                position: relative;
    
                &:after{
                    position: absolute;
                    top: -44px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 0%;
                    content: ".";
                    color: transparent;
                    background: $clr-ieser;
                    height: 3px;
                }
    
                &:hover:after{
                    width: 100%;
                }

                &.active{
                    &:after{
                        position: absolute;
                        top: -44px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 100%;
                        content: ".";
                        color: transparent;
                        background: $clr-ieser;
                        height: 3px;
                    }
                }
            }
        }         
    }
}


//CUSTOM MIXINS
@mixin margin-inicio{
    margin-top: 20vh;

    @include bp-1024{
        margin-top: 11vh;
    }
}


@mixin headings{
    h2{
        font-style: italic;
        font-size: size(62);
        line-height: size(60);
        margin: 0;
        font-family: $font-primary-book;
        width: fit-content;

        @include bp-1440{
            font-size: size(55);
            line-height: size(57);
        }

        @include bp-576{
            font-size: size(40);
            line-height: size(42);
        }
    }

    h1, h4{
        font-size: size(60);
        line-height: size(60);
        margin: 7px 0;
        width: fit-content;

        @include bp-1440{
            font-size: size(55);
            line-height: size(57);
        }

        @include bp-576{
            font-size: size(40);
            line-height: size(42);
        }
    }

    h3{
        font-family: $font-mono2;
        font-size: size(50);
        line-height: size(50);
        width: fit-content;

        @include bp-1440{
            font-size: size(45);
            line-height: size(47);
        }

        @include bp-576{
            font-size: size(27);
            line-height: size(32);
        }
    }
}