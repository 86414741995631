    /*
    CONTAINER DEFAULTS
    * Don't modify vars here
*/
$container-default: 1400 !default;
$container-sm: 750 !default;
$container-md: 900 !default;
$container-lg: 1200 !default;
$container-xl: 100% !default;

$container-default-gutter: 50;
$container-sm-gutter: 50;
$container-md-gutter: 50;
$container-lg-gutter: 50;
$container-xl-gutter: 20;

$container-gutter-responsive: 20;

$container-default-expand: $container-default + ($container-default-gutter * 2);
$container-sm-expand: $container-sm + ($container-sm-gutter * 2);
$container-md-expand: $container-md + ($container-md-gutter * 2);
$container-lg-expand: $container-lg + ($container-lg-gutter * 2);
$container-xl-expand: $container-xl + ($container-xl-gutter * 2);

$container-default-responsive: 576;


/*
    MIXINS
    ARGUMENTS:
    - $container-default-responsive[ int - default: 576 ]
    Reduce padding of container on mobile
*/
@mixin responsiveGutter() {
   @include bp($container-default-responsive) {
        padding-right: size($container-gutter-responsive);
        padding-left: size($container-gutter-responsive);
    }
}

/*
    CONTAINER ELEMENTS
*/
.-container {
    width: $container-default + px;
    max-width: $container-default + px;
    margin-right: auto;
    margin-left: auto;
    padding-right: size($container-default-gutter);
    padding-left: size($container-default-gutter);
    @include bp($container-default-expand) {
        width: 90%;
        max-width: 90%;
    }
    @include responsiveGutter();
}

.-container-sm {
    width: $container-sm + px;
    max-width: $container-sm + px;
    margin-right: auto;
    margin-left: auto;
    padding-right: size($container-sm-gutter);
    padding-left: size($container-sm-gutter);
    @include bp($container-sm-expand) {
        width: 100%;
        max-width: 100%;
    }
    @include responsiveGutter();
}

.-container-md {
    width: $container-md + px;
    max-width: $container-md + px;
    margin-right: auto;
    margin-left: auto;
    padding-right: size($container-md-gutter);
    padding-left: size($container-md-gutter);
    @include bp($container-md-expand) {
        width: 100%;
        max-width: 100%;
    }
    @include responsiveGutter();
}

.-container-lg {
    width: $container-lg + px;
    max-width: $container-lg + px;
    margin-right: auto;
    margin-left: auto;
    padding-right: size($container-lg-gutter);
    padding-left: size($container-lg-gutter);
    @include bp($container-lg-expand) {
        width: 100%;
        max-width: 100%;
    }
    @include responsiveGutter();
}

.-container-xl {
    width: $container-xl;
    max-width: $container-xl;
    margin-right: auto;
    margin-left: auto;
    padding-right: size($container-xl-gutter);
    padding-left: size($container-xl-gutter);
    @include bp($container-xl-expand) {
        width: 100%;
        max-width: 100%;
    }
    @include responsiveGutter();
}

.-container-full {
    width: 100vw;
    padding-right: size($container-default-gutter);
    padding-left: size($container-default-gutter);
}

.-container--full {
    width: 100vw;
    padding-right: 0;
    padding-left: 0;
}



